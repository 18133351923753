import { makeRequest } from './make-request';
import { fetchWithPromise } from './fetch-with-cache';

export async function newOrg(data) {
  return makeRequest('post', 'orgs', data);
}

export async function updateOrg(update, orgId) {
  const { data, errorMessage } = await makeRequest('put', 'orgs', update, orgId);
  return data || { errorMessage };
}

export async function deleteOrg(orgId) {
  const { data, errorMessage } = await makeRequest('delete', 'orgs', undefined, orgId);
  return data || { errorMessage };
}

export async function getOrganizations(limit, name, page = 0, orderBy, active) {
  const key = `get-orgs-${limit},${page},${name},${orderBy},${active}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'orgs', {
    limit,
    page,
    name,
    orderBy,
    active
  });
  return data ? { records: data.organizations, totalCount: data.totalCount } : { errorMessage };
}

export async function getOrgPermissions(orgId) {
  const key = `get-org-permissions-${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, 'orgs/permissions', { orgId });
  return data ? { records: data, totalCount: data.length } : { errorMessage };
}

export async function getSupportedZipCodes(orgId) {
  const key = `get-supported-zip-codes-${orgId}`;
  const { data, errorMessage } = await fetchWithPromise(key, `s3-business-settings/record/${orgId}`);
  const codes = data?.supportedPostalCodes;
  if (codes) {
    return { data: Object.keys(codes) };
  } else if (data) {
    return { data: undefined };
  }
  return { errorMessage };
}

export async function updateSupportedZipCodes(orgId, postalCodes) {
  const { data, errorMessage } = await makeRequest('put', 's3-business-settings/postal-codes', { postalCodes }, orgId);
  return data || { errorMessage };
}
